@use "./variables.scss" as v;
@import url("./questionario.scss");

.evolui-logo {
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 2.25rem;
  text-decoration: none;
  color: #000;
  transition: all 0.3s;

  &:hover {
    color: v.$primary-color;
  }
}

.quest-title {
  .title {
    font-size: 2.75rem;
    font-weight: 600;
  }
}

.ba-progress {
  border-radius: 2.5rem;
  background: #e9edfd;
  margin: 24px 0;

  .progress-bar {
    background: v.$primary-color;
  }
}

.ba-button {
  background-color: #fff;
  color: #000;
  border-radius: 2rem;
  transition: all 0.3s;
  padding: 10px 1.75rem;
  box-shadow: none;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  border: 2px solid #ebebeb;
  text-decoration: none;

  &:hover {
    border-color: v.$primary-color;
  }

  &.primary-button {
    background-color: v.$primary-color;
    color: #fff;
    border-color: v.$primary-color;

    &:hover {
      opacity: 0.9;
    }
  }

  &.secondary-button {
    background-color: #fff;
    color: #000;

    &:hover {
      border-color: v.$primary-color;
    }
  }

  &.primary-border-button {
    background-color: #fff;
    color: #000;
    border-color: v.$primary-color;

    &:hover {
      border-color: #000;
    }
  }

  .button-icon-left {
    padding-right: 8px;
  }

  .button-icon-right {
    padding-left: 8px;
  }
}

.questionarios-list-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.questionarios-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.title {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
}

.questionarios-tabs.nav-tabs {
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 200px;
  flex-wrap: nowrap;
  margin-top: -40px;

  .nav-item {
    .nav-link {
      border: 0;
      border-radius: 200px;
      color: #000;
      transition: all 0.3s;
      padding: 8px 18px;

      &:hover {
        border: 0;
        background-color: #e9edfd;
      }

      &.active {
        background-color: v.$primary-color;
        color: #fff;
      }
    }
  }
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: v.$primary-color v.$primary-color transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent #000 #000;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@media only screen and (max-device-width: 768px) {
  .quest-title {
    .title {
      font-size: 2.4rem;
    }
  }
}
