@use "./variables.scss" as v;

.survey-navigation {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.sd-title.sd-container-modern__title {
  box-shadow: none !important;

  .sd-header__text .sd-title {
    color: #000;
    text-align: center;
  }
}
.sd-input {
  border: 2px solid #ebebeb !important;
  box-shadow: none !important;
}

.sd-multipletext__item {
  .sd-input {
    border: none !important;
  }
}

.sd-question--disabled {
  .sd-multipletext__item-title {
    opacity: 0.25;
  }
}

.sd-body.sd-body--static {
  padding-bottom: 40px !important;
}

.sd-boolean {
  border: 2px solid #ebebeb;
  box-shadow: none !important;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    border-color: v.$primary-color;
  }

  .sd-boolean__thumb {
    background: v.$primary-color;
    color: #fff !important;
    box-shadow: none;
  }

  .sv-string-viewer {
    font-weight: 500;
  }

  &.sd-boolean--disabled {
    .sd-boolean__thumb {
      color: #fff;
    }
  }
}

.sd-radio {
  .sd-selectbase__label {
    border: 2px solid #ebebeb;
    border-radius: 2.5rem;
    padding: 10px;
    transition: all 0.3s;
    background: #fff;
    cursor: pointer;
    height: 100%;
    width: 100%;

    &:hover {
      border-color: v.$primary-color;
    }

    .sd-item__control-label {
      text-align: center;
      color: #000;
      font-weight: 500;
    }

    .sd-radio__decorator {
      display: none !important;
    }
  }

  &.sd-radio--checked {
    .sd-selectbase__label {
      border-color: v.$primary-color;
      background: v.$primary-color;

      .sd-item__control-label {
        color: #fff !important;
      }
    }
  }

  &.sd-radio--disabled {
    pointer-events: none;

    &.sd-radio--checked {
      opacity: 0.25;
      .sd-item__control-label {
        opacity: 1;
      }
    }
  }
}

.sd-rating__item {
  border: 2px solid #ebebeb !important;
  background: #fff !important;
  box-shadow: none !important;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    border-color: v.$primary-color !important;
  }

  .sd-rating__item-text {
    font-weight: 500 !important;
  }

  &.sd-rating__item--selected {
    border-color: v.$primary-color !important;
    background-color: v.$primary-color !important;
  }

  &.sd-rating__item--disabled {
    pointer-events: none;

    &.sd-rating__item--selected {
      opacity: 0.25;

      .sd-rating__item-text {
        opacity: 1;
      }
    }
  }
}

.sd-body__navigation .sd-btn {
  font-family: "Montserrat";
  background-color: v.$primary-color !important;
  color: #fff !important;
  border-radius: 2rem !important;
  transition: all 0.3s !important;
  padding: 10px 1.75rem !important;
  box-shadow: none !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  border: 2px solid v.$primary-color !important;
  line-height: unset !important;

  &:hover {
    opacity: 0.9;
  }
}

.sd-btn.sd-navigation__prev-btn {
  background-color: #fff !important;
  color: #000 !important;
  border-color: #ebebeb !important;

  &:hover {
    border-color: v.$primary-color !important;
  }
}

.results-question {
  font-weight: bold;
  margin-bottom: 6px;
}
.results-answer {
  padding-left: 14px;
}
.ba-fileinput {
  background: #fff;
  border: 2px solid #ebebeb;
  border-radius: 100px;
  padding: 10px;

  &::file-selector-button {
    background-color: #ebebeb;
    border-radius: 2rem;
    border: 2px solid #ebebeb;
    padding: 4px 12px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      border-color: v.$primary-color;
    }
  }
}

.sd-file--single-image .sd-file__sign a {
  color: rgba($color: #000000, $alpha: 1) !important;
}

.sd-element--with-frame.sd-element--compact {
  background-color: #fff !important;
  border: 2px solid #ebebeb !important;
}

.sd-item {
  padding: 4px !important;
}
.sd-multipletext tr:not(:last-child) .sd-multipletext__cell {
  padding-bottom: 8px !important;
}
.sd-question__header--location-top {
  padding-bottom: 8px !important;
}

#questionDescriptionPopup {
  background-color: #fff;
  border-radius: 24px;
  border: 0;
  box-shadow: 0 0 24px rgba($color: #000000, $alpha: 0.05);
  padding: 10px;
  /* width: 60%; */

  video,
  img,
  iframe {
    border-radius: 16px;
  }
}

.sv-string-viewer {
  a {
    text-decoration: none;
    color: v.$primary-color;
    transition: all 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }
}

@media only screen and (max-device-width: 768px) {
  .sd-body .sd-body__page {
    min-width: unset !important;
  }

  #questionDescriptionPopup {
    /* width: 90%; */
    .d-flex.gap-4 {
      div:last-child {
        padding-right: 10px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1020px;
  }
}

.questionario-ler {
  * {
    opacity: 1 !important;
  }
}
